



































































































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import ValidatedSwitch from '@/components/forms/ValidatedSwitch.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import PlayroomEditPlayroomAdditionPartViewModel from '@/models/PlayroomEditPlayroomAdditionPartViewModel';
import PlayroomAddition from '@/models/PlayroomAddition';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import ErrorMessage from '@/components/shared/ErrorMessage.vue';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import mustBePositivMoneyAmount from '@/validators/MustBePositivMoneyAmount';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import DeleteButton from '@/components/forms/DeleteButton.vue';
import OwnerPlayroomAdditionService from '@/services/owner/OwnerPlayroomAdditionService';
import { ServerResponse } from '@/services/ApiService';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import draggable from 'vuedraggable';

const validations = {
  playroom: {
    additions: {
      $each: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(300),
        },
        sortOrder: {},
        forBookingType: { required },
        isPricePerPiece: {},
        isPricePerTime: {},
        priceForHourly: { mustBePositivMoneyAmount },
        priceForOvernight: { mustBePositivMoneyAmount },
      },
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: {
    ValidatedFormCard,
    ErrorMessage,
    DeleteButton,
    ValidatedInput,
    ValidatedTextArea,
    ValidatedSwitch,
    ValidatedSelect,
    draggable,
  },
  mixins: [HelpCardMixin, validationMixin],
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  validations,
  data() {
    return {
      playroom: {} as PlayroomEditPlayroomAdditionPartViewModel,
      errorMessage: '',
      ready: false,
      activeCollapsePlayroomIndex: -1,
      drag: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    stillAvailableCount(): number {
      return 50 - this.playroom.additions.length;
    },
    additionTypeOptions(): { label: string; value: string }[] {
      return ['Hourly', 'Overnight', 'All'].map((o) => ({
        label: this.$t('components.PlayroomAdditionEditForm.forBookingType.' + o).toString(),
        value: o,
      }));
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditPlayroomAdditionPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditPlayroomAdditionPartViewModel;
        this.playroom.additions = this.playroom.additions as PlayroomAddition[];
        this.ready = true;
        this.activeCollapsePlayroomIndex = 0;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    onDragChange() {
      this.$v.$touch();
    },

    addNewAddition() {
      if (this.stillAvailableCount > 0) {
        const playroomAddition = new PlayroomAddition(this.playroomId);
        playroomAddition.sortOrder = this.playroom.additions.length;
        this.playroom.additions.push(playroomAddition);
        this.activeCollapsePlayroomIndex = this.playroom.additions.length - 1;
      }
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.PlayroomAdditionPart.info.box1';
      }
    },

    deactivatePlayroomAddition(addition: PlayroomAddition): void {
      OwnerPlayroomAdditionService.deactivate(addition.playroomId, addition.id).then(() => {
        this.loadData();
      });
    },

    activatePlayroomAddition(addition: PlayroomAddition): void {
      OwnerPlayroomAdditionService.activate(addition.playroomId, addition.id).then(() => {
        this.loadData();
      });
    },

    onDeletedPlayroomAddition(addition: PlayroomAddition, index: number) {
      this.playroom.additions.splice(index, 1);
      if (addition.id) {
        return OwnerPlayroomAdditionService.delete(addition.playroomId, addition.id);
      }
    },

    collapseClick(index: number) {
      if (this.activeCollapsePlayroomIndex === index) {
        // Close is click on open card
        this.activeCollapsePlayroomIndex = -1;
      } else {
        this.activeCollapsePlayroomIndex = index;
      }
    },

    disablePriceForHourlyInput(addition: PlayroomAddition): boolean {
      return addition.forBookingType == 'Overnight';
    },

    disablePriceForOvernightInput(addition: PlayroomAddition): boolean {
      return addition.forBookingType == 'Hourly';
    },

    appendForOvernight(addition: PlayroomAddition): string {
      let translationsString = 'appendForOvernight';
      if (addition.isPricePerPiece) {
        translationsString += 'PerPiece';
      }
      if (addition.isPricePerTime) {
        translationsString += 'PerTime';
      }
      return this.$t('components.PlayroomAdditionEditForm.' + translationsString).toString();
    },

    appendForHourly(addition: PlayroomAddition): string {
      let translationsString = 'appendForHourly';
      if (addition.isPricePerPiece) {
        translationsString += 'PerPiece';
      }
      if (addition.isPricePerTime) {
        translationsString += 'PerTime';
      }
      return this.$t('components.PlayroomAdditionEditForm.' + translationsString).toString();
    },

    showBookingTypeConflictError(addition: PlayroomAddition): boolean {
      if (!this.playroom.allowHourBooking && addition.forBookingType == 'Hourly') {
        return true;
      }
      if (!this.playroom.allowOvernightStay && addition.forBookingType == 'Overnight') {
        return true;
      }
      if ((!this.playroom.allowHourBooking || !this.playroom.allowOvernightStay) && addition.forBookingType == 'All') {
        return true;
      }
      return false;
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    save(): Promise<ServerResponse> {
      for (let i = 0; i < this.playroom.additions.length; i++) {
        this.playroom.additions[i].sortOrder = i;
      }
      const clone = JSON.parse(JSON.stringify(this.playroom));
      var response = OwnerPlayroomAdditionService.savePart(clone).then((response) => {
        this.loadData();
        return response;
      });

      return response;
    },
  },
});
