

























































































































































































































































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

const mustBePositivMoneyAmount = (value: string) => {
  return /^[0-9]+(\,[0-9]{1,2})?$/.test(value);
};

class PlayroomEditRatesPartViewModel {
  id!: string;
  allowHourBooking!: boolean;
  allowOvernightStay!: boolean;
  hourlyWeekPrice!: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    beforeHoliday: string;
    onHoliday: string;
  };
  overnightStayWeekPrice!: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    beforeHoliday: string;
    onHoliday: string;
  };
  allowedDaysForHourlyBooking!: {
    onMonday: {};
    onTuesday: {};
    onWednesday: {};
    onThursday: {};
    onFriday: {};
    onSaturday: {};
    onSunday: {};
    beforeHoliday: {};
    onHoliday: {};
  };
  allowedDaysForOvernightBooking!: {
    onMonday: {};
    onTuesday: {};
    onWednesday: {};
    onThursday: {};
    onFriday: {};
    onSaturday: {};
    onSunday: {};
    beforeHoliday: {};
    onHoliday: {};
  };
}

const validations = {
  playroom: {
    hourlyWeekPrice: {
      monday: {
        required,
        mustBePositivMoneyAmount,
      },
      tuesday: {
        required,
        mustBePositivMoneyAmount,
      },
      wednesday: {
        required,
        mustBePositivMoneyAmount,
      },
      thursday: {
        required,
        mustBePositivMoneyAmount,
      },
      friday: {
        required,
        mustBePositivMoneyAmount,
      },
      saturday: {
        required,
        mustBePositivMoneyAmount,
      },
      sunday: {
        required,
        mustBePositivMoneyAmount,
      },
      beforeHoliday: {
        required,
        mustBePositivMoneyAmount,
      },
      onHoliday: {
        required,
        mustBePositivMoneyAmount,
      },
    },
    overnightStayWeekPrice: {
      monday: {
        required,
        mustBePositivMoneyAmount,
      },
      tuesday: {
        required,
        mustBePositivMoneyAmount,
      },
      wednesday: {
        required,
        mustBePositivMoneyAmount,
      },
      thursday: {
        required,
        mustBePositivMoneyAmount,
      },
      friday: {
        required,
        mustBePositivMoneyAmount,
      },
      saturday: {
        required,
        mustBePositivMoneyAmount,
      },
      sunday: {
        required,
        mustBePositivMoneyAmount,
      },
      beforeHoliday: {
        required,
        mustBePositivMoneyAmount,
      },
      onHoliday: {
        required,
        mustBePositivMoneyAmount,
      },
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, ValidatedFormCard },
  mixins: [validationMixin, HelpCardMixin],
  validations() {
    const vals = {
      playroom: {
        hourlyWeekPrice: {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {},
          beforeHoliday: {},
          onHoliday: {},
        },
        overnightStayWeekPrice: {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {},
          beforeHoliday: {},
          onHoliday: {},
        },
      },
    };
    if (this.playroom.allowHourBooking) {
      vals.playroom.hourlyWeekPrice = validations.playroom.hourlyWeekPrice;
    }
    if (this.playroom.allowOvernightStay) {
      vals.playroom.overnightStayWeekPrice = validations.playroom.overnightStayWeekPrice;
    }
    return vals;
  },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditRatesPartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditRatesPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditRatesPartViewModel;
        this.playroom.hourlyWeekPrice.monday = this.transformInput(this.playroom.hourlyWeekPrice.monday);
        this.playroom.hourlyWeekPrice.tuesday = this.transformInput(this.playroom.hourlyWeekPrice.tuesday);
        this.playroom.hourlyWeekPrice.wednesday = this.transformInput(this.playroom.hourlyWeekPrice.wednesday);
        this.playroom.hourlyWeekPrice.thursday = this.transformInput(this.playroom.hourlyWeekPrice.thursday);
        this.playroom.hourlyWeekPrice.friday = this.transformInput(this.playroom.hourlyWeekPrice.friday);
        this.playroom.hourlyWeekPrice.saturday = this.transformInput(this.playroom.hourlyWeekPrice.saturday);
        this.playroom.hourlyWeekPrice.sunday = this.transformInput(this.playroom.hourlyWeekPrice.sunday);
        this.playroom.hourlyWeekPrice.beforeHoliday = this.transformInput(this.playroom.hourlyWeekPrice.beforeHoliday);
        this.playroom.hourlyWeekPrice.onHoliday = this.transformInput(this.playroom.hourlyWeekPrice.onHoliday);
        this.playroom.overnightStayWeekPrice.monday = this.transformInput(this.playroom.overnightStayWeekPrice.monday);
        this.playroom.overnightStayWeekPrice.tuesday = this.transformInput(
          this.playroom.overnightStayWeekPrice.tuesday
        );
        this.playroom.overnightStayWeekPrice.wednesday = this.transformInput(
          this.playroom.overnightStayWeekPrice.wednesday
        );
        this.playroom.overnightStayWeekPrice.thursday = this.transformInput(
          this.playroom.overnightStayWeekPrice.thursday
        );
        this.playroom.overnightStayWeekPrice.friday = this.transformInput(this.playroom.overnightStayWeekPrice.friday);
        this.playroom.overnightStayWeekPrice.saturday = this.transformInput(
          this.playroom.overnightStayWeekPrice.saturday
        );
        this.playroom.overnightStayWeekPrice.sunday = this.transformInput(this.playroom.overnightStayWeekPrice.sunday);
        this.playroom.overnightStayWeekPrice.beforeHoliday = this.transformInput(
          this.playroom.overnightStayWeekPrice.beforeHoliday
        );
        this.playroom.overnightStayWeekPrice.onHoliday = this.transformInput(
          this.playroom.overnightStayWeekPrice.onHoliday
        );
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveRatesPart(this.playroom);
        this.loadData();
        return response.success;
      } else {
        return false;
      }
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.RatesPart.info.box1';
      }
    },

    copyHourlyToAll() {
      this.playroom.hourlyWeekPrice.tuesday = this.playroom.hourlyWeekPrice.monday;
      this.playroom.hourlyWeekPrice.wednesday = this.playroom.hourlyWeekPrice.monday;
      this.playroom.hourlyWeekPrice.thursday = this.playroom.hourlyWeekPrice.monday;
      this.playroom.hourlyWeekPrice.friday = this.playroom.hourlyWeekPrice.monday;
      this.playroom.hourlyWeekPrice.saturday = this.playroom.hourlyWeekPrice.monday;
      this.playroom.hourlyWeekPrice.sunday = this.playroom.hourlyWeekPrice.monday;
      this.playroom.hourlyWeekPrice.beforeHoliday = this.playroom.hourlyWeekPrice.monday;
      this.playroom.hourlyWeekPrice.onHoliday = this.playroom.hourlyWeekPrice.monday;
      (this.$v.playroom.hourlyWeekPrice as any).$touch();
    },

    copyOvernightToAll() {
      this.playroom.overnightStayWeekPrice.tuesday = this.playroom.overnightStayWeekPrice.monday;
      this.playroom.overnightStayWeekPrice.wednesday = this.playroom.overnightStayWeekPrice.monday;
      this.playroom.overnightStayWeekPrice.thursday = this.playroom.overnightStayWeekPrice.monday;
      this.playroom.overnightStayWeekPrice.friday = this.playroom.overnightStayWeekPrice.monday;
      this.playroom.overnightStayWeekPrice.saturday = this.playroom.overnightStayWeekPrice.monday;
      this.playroom.overnightStayWeekPrice.sunday = this.playroom.overnightStayWeekPrice.monday;
      this.playroom.overnightStayWeekPrice.beforeHoliday = this.playroom.overnightStayWeekPrice.monday;
      this.playroom.overnightStayWeekPrice.onHoliday = this.playroom.overnightStayWeekPrice.monday;
      (this.$v.playroom.overnightStayWeekPrice as any).$touch();
    },
  },
  computed: {
    transformInput(): (value: string) => string {
      return (value: string) => {
        var input = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(Number(value));
        return input;
      };
    },
    transformOutput(): (value: string) => number {
      return (value: string) => {
        const output = parseFloat(value.replace(/\./g, '').replace(',', '.'));
        return output;
      };
    },
  },
});
