


















































































































































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import { maxValue, minValue, required } from 'vuelidate/lib/validators';

class PlayroomEditBasicChargePartViewModel {
  id!: string;
  baseRentForHourly!: number;
  baseRentForOvernight!: number;
  allowSurchargePerPersonPerBookingForHourly!: boolean;
  allowSurchargePerPersonPerBookingForOvernight!: boolean;
  surchargePerPersonPerBookingForHourly!: number;
  surchargePerPersonPerBookingForOvernight!: number;
  allowSurchargePerPersonPerHour!: boolean;
  surchargePerPersonPerHour!: number;
  allowSurchargePerPersonPerNight!: boolean;
  surchargePerPersonPerNight!: number;
  bedTaxType!: string;
  bedTax!: number;
  showBedTax!: boolean;
  allowOvernightStay!: boolean;
  allowHourBooking!: boolean;
  reducedTaxPercentage!: number;
}

const mustBePositivMoneyAmount = (value: string) => {
  if (value === null) {
    return true;
  }
  return /^\d+([\,\.]\d{1,2})?$/.test(value);
};

const isNotZero = (value: string) => {
  if (value === null) {
    return true;
  }
  return Number(value) != 0;
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedFormCard, ValidatedInput, ValidatedCheckbox, ValidatedSelect },
  mixins: [validationMixin, HelpCardMixin],
  validations() {
    const vals = {
      playroom: {
        baseRentForHourly: {
          mustBePositivMoneyAmount,
        },
        baseRentForOvernight: {
          mustBePositivMoneyAmount,
        },
        reducedTaxPercentage: {
          minValue: minValue(0),
          maxValue: maxValue(100),
          required,
        },
        allowSurchargePerPersonPerBookingForHourly: {},
        allowSurchargePerPersonPerBookingForOvernight: {},
        surchargePerPersonPerBookingForHourly: {},
        surchargePerPersonPerBookingForOvernight: {},
        allowSurchargePerPersonPerHour: {},
        surchargePerPersonPerHour: {},
        allowSurchargePerPersonPerNight: {},
        surchargePerPersonPerNight: {},
        bedTaxType: {},
        bedTax: {
          mustBePositivMoneyAmount,
        },
        showBedTax: {},
      },
    };
    if (this.playroom.allowSurchargePerPersonPerBookingForHourly) {
      vals.playroom.surchargePerPersonPerBookingForHourly = {
        mustBePositivMoneyAmount,
        isNotZero,
      };
    }
    if (this.playroom.allowSurchargePerPersonPerBookingForOvernight) {
      vals.playroom.surchargePerPersonPerBookingForOvernight = {
        mustBePositivMoneyAmount,
        isNotZero,
      };
    }
    if (this.playroom.allowSurchargePerPersonPerHour) {
      vals.playroom.surchargePerPersonPerHour = {
        mustBePositivMoneyAmount,
        isNotZero,
      };
    }
    if (this.playroom.allowSurchargePerPersonPerNight) {
      vals.playroom.surchargePerPersonPerNight = {
        mustBePositivMoneyAmount,
        isNotZero,
      };
    }
    return vals;
  },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  watch: {
    showBedTax() {
      if (this.ready) {
        if (!this.showBedTax) {
          this.playroom.bedTaxType = 'NoBedTax';
          this.playroom.bedTax = 0;
        } else {
          this.playroom.bedTaxType = 'PerBooking';
          this.playroom.bedTax = 0;
        }
      }
    },
    playroom: {
      deep: true,
      handler() {
        if (this.playroom.allowSurchargePerPersonPerBookingForHourly) {
          this.playroom.allowSurchargePerPersonPerHour = false;
          this.playroom.surchargePerPersonPerHour = 0;
        }
        if (this.playroom.allowSurchargePerPersonPerBookingForOvernight) {
          this.playroom.allowSurchargePerPersonPerNight = false;
          this.playroom.surchargePerPersonPerNight = 0;
        }
        if (this.playroom.allowSurchargePerPersonPerHour) {
          this.playroom.allowSurchargePerPersonPerBookingForHourly = false;
          this.playroom.surchargePerPersonPerBookingForHourly = 0;
        }
        if (this.playroom.allowSurchargePerPersonPerNight) {
          this.playroom.allowSurchargePerPersonPerBookingForOvernight = false;
          this.playroom.surchargePerPersonPerBookingForOvernight = 0;
        }
      },
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditBasicChargePartViewModel,
      ready: false,
      showBedTax: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditBasicChargePart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditBasicChargePartViewModel;
        this.playroom.showBedTax = !!this.playroom.bedTax;
        this.showBedTax = !!this.playroom.bedTax;
        this.$nextTick(() => {
          this.ready = true;
        });
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.BasicChargePart.info.box1';
      }
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveBasicChargePart(this.playroomId, { ...this.playroom });
        if (response.success) {
          this.$emit('part-save-status-type-change', PartSaveStatusType.Saved);
          this.$v.$reset();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
  computed: {
    bedTaxTypeOptions(): { label: string; value: string }[] {
      return [
        { label: this.$t('enums.bedTaxTypes.PerBooking').toString(), value: 'PerBooking' },
        { label: this.$t('enums.bedTaxTypes.PerNight').toString(), value: 'PerNight' },
        { label: this.$t('enums.bedTaxTypes.PerGuestPerNight').toString(), value: 'PerGuestPerNight' },
        { label: this.$t('enums.bedTaxTypes.PercentageOfPrice').toString(), value: 'PercentageOfPrice' },
      ];
    },
    bedTaxAppendText(): string {
      const translationKey =
        'components.playroomEditParts.BasicChargePart.bedTaxAppendText.' + this.playroom.bedTaxType;
      return this.$t(translationKey).toString();
    },
    // Hourly Per Booking => Not PerHour & Hourly allowed
    enableAllowSurchargePerPersonPerBookingForHourly(): boolean {
      return !this.playroom.allowSurchargePerPersonPerHour && this.playroom.allowHourBooking;
    },
    enableAllowSurchargePerPersonPerBookingForOvernight(): boolean {
      return !this.playroom.allowSurchargePerPersonPerNight && this.playroom.allowOvernightStay;
    },
    enableAllowSurchargePerPersonPerHour(): boolean {
      return !this.playroom.allowSurchargePerPersonPerBookingForHourly && this.playroom.allowHourBooking;
    },
    enableAllowSurchargePerPersonPerNight(): boolean {
      return !this.playroom.allowSurchargePerPersonPerBookingForOvernight && this.playroom.allowOvernightStay;
    },
  },
});
