import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/PlayroomAddition/';

class OwnerPlayroomAdditionService {
  deactivate(playroomId: string, playroomAdditionId: string) {
    return ApiService.post(`${API_URL}Deactivate`, { playroomId, playroomAdditionId });
  }

  activate(playroomId: string, playroomAdditionId: string) {
    return ApiService.post(`${API_URL}Activate`, { playroomId, playroomAdditionId });
  }

  delete(playroomId: string, playroomAdditionId: string) {
    return ApiService.delete(`${API_URL}DeletePart?playroomAdditionId=${playroomAdditionId}&playroomId=${playroomId}`);
  }

  savePart(playroom: {
    additions: {
      forBookingType: string;
      isPricePerPiece: boolean | string;
      isPricePerTime: boolean | string;
      priceForHourly: string | number;
      priceForOvernight: string | number;
      sortOrder: number | string;
      isActive: boolean | string;
    }[];
  }) {
    playroom.additions.forEach((playroomAddition) => {
      playroomAddition.isPricePerPiece = String(playroomAddition.isPricePerPiece);
      playroomAddition.isPricePerTime = String(playroomAddition.isPricePerTime);
      playroomAddition.sortOrder = String(playroomAddition.sortOrder);
      playroomAddition.isActive = String(playroomAddition.isActive);
      playroomAddition.priceForHourly = String(playroomAddition.priceForHourly);
      playroomAddition.priceForOvernight = String(playroomAddition.priceForOvernight);
    });
    return ApiService.post(`${API_URL}EditPart`, playroom);
  }

  getForEdit(playroomId: string, playroomAdditionId: string) {
    return ApiService.get(`${API_URL}Edit?playroomId=${playroomId}&playroomAdditionId=${playroomAdditionId}`);
  }
}

export default new OwnerPlayroomAdditionService();
