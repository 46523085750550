export default class PlayroomAddition {
  constructor(playroomId: string) {
    this.name = '';
    this.description = '';
    this.id = '';
    this.playroomId = playroomId;
    this.isActive = true;
    this.forBookingType = 'All';
    this.priceForOvernight = '0';
    this.priceForHourly = '0';
    this.isPricePerPiece = false;
    this.isPricePerTime = false;
    this.canBeDeleted = true;
  }
  name!: string;
  description!: string;
  id!: string;
  playroomId!: string;
  isActive!: boolean;
  sortOrder!: number;
  forBookingType!: string;
  priceForOvernight!: string;
  priceForHourly!: string;
  isPricePerPiece!: boolean;
  isPricePerTime!: boolean;
  canBeDeleted!: boolean;
}
